export const DEFAULT_API_URL =
  process.env.REACT_APP_API_BASE ??
  (process.env.NODE_ENV === "production"
    ? "https://app.dromo.io"
    : "http://localhost:8000");

export const METADATA_PREVIEW_MODAL_NUM_ROWS = 10;
export const METADATA_PREVIEW_MODAL_NUM_COLS = 3;
export const COLUMN_MATCH_MODAL_PREVIEW_NUM_ROWS = 4;
export const DEFAULT_MAX_UNIQUE_VALUES_SELECT_FIELD = 50;
export const MAX_MAX_UNIQUE_VALUES_SELECT_FIELD = 1000;

export const MAX_AI_SELECT_OPTION_UNIQUES = 50;

export const MAX_UPLOAD_FILE_SIZE = 1073741824; // 1 GiB

export const DEVELOPMENT_MODE_RESULT_ROW_LIMIT = 100;
export const DEVELOPMENT_MODE_HEADLESS_LIMIT = 1;

export const HANDSONTABLE_LICENSE_KEY = "8dc30-360c6-96ee2-b4b3f-aed00";

export const VERSION_TWO = "v2";

export const serverFiletypes = new Set<string>(["xls", "xlsx", "xml"]);
export const localFiletypes = new Set<string>(["tsv", "csv", "txt"]);
export const allFiletypes = new Set([...serverFiletypes, ...localFiletypes]);

export const NUM_ROWS_FOR_PREVIEW = 30;

export const MIN_WINDOW_WIDTH_PIXELS = 780;

// The default fuzzy matching threshold of 0.6 is TOO fuzzy for column matching
// Lowing this to 0.4 should reduce annoying false postives.
// A mismatched column that's not noticed could be very problematic
// Experimenting with the FUSE demo: https://fusejs.io/demo.html, I found exact matches weren't zero
// 0.00001 low enough to filter all but exact matches
export const FUSE_FUZZY_MATCH_THRESHOLD = 0.4;
export const FUSE_LESS_FUZZY_MATCH_THRESHOLD = 0.2;
export const FUSE_EXACT_MATCH_THRESHOLD = 0.00001;

export const AI_MATCHING_TIMEOUT_MS = 10000;
export const AI_MATCHING_MAX_TERMS = 100;
