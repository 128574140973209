import {
  DEFAULT_API_URL,
  DEFAULT_MAX_UNIQUE_VALUES_SELECT_FIELD,
  allFiletypes,
  localFiletypes,
} from "../../constants/constants";
import {
  IDeveloperSettingsStrict,
  IDeveloperStyleOverridesStrict,
  TBackendType,
  IBackendCapabilities,
  IUser,
  ISavedSchema,
  EInvalidDataBehavior,
  EBackendSyncMode,
  IAllHooks,
} from "../../interfaces";
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../reducers";
import {
  DeveloperSettingsSchema,
  IDeveloperSettings,
} from "../../helpers/schemas/settings";
import { selectCustomFileParserExtensions } from "./coredata";
import { IOperation } from "../../thunks/operations";

export type TImporterMode = "INIT" | "DEMO" | "DEVELOPMENT" | "PRODUCTION";

export interface ISettingsReduxState {
  licenseKey: string;
  importIdentifier: string;
  title: string | null;
  user: IUser;
  styleOverrides: IDeveloperStyleOverridesStrict;
  importerMode: TImporterMode;
  backend: {
    url: string;
    type: TBackendType;
  };
  backendCapabilities: IBackendCapabilities;
  maxRecords: number | null;
  maxFileSize: number;
  browserExcelParsing: boolean;
  headerRowOverride: number | null;
  maxMappableSelectValues: number;
  delimiter: string | undefined;
  autoMapHeaders: boolean;
  savedSchema: null | ISavedSchema;
  invalidDataBehavior: EInvalidDataBehavior;
  embedInline: boolean;
  allowEmptySubmit: boolean;
  appHost: null | string;
  backendSyncMode: EBackendSyncMode;
  webhookUrl: null | string;
  manualInputDisabled: boolean;
  manualInputOnly: boolean;
  templateDownloadFilename: null | string;
  uploadStep: IDeveloperSettingsStrict["uploadStep"];
  matchingStep: IDeveloperSettingsStrict["matchingStep"];
  matchValuesStep: IDeveloperSettingsStrict["matchValuesStep"];
  reviewStep: IDeveloperSettingsStrict["reviewStep"];
  allowCustomFields: boolean;
  passThroughUnmappedColumns: boolean;
  version: string;
  originalSettings?: IDeveloperSettings;
  isHeadless: boolean; // whether we are running in a headless context right now
  savedSchemaHooks: IAllHooks;
  operations: IOperation[];
}

export const defaultBackendCapabilities: IBackendCapabilities = {
  accept_json_results: false,
  ai_matching: false,
  allow_auto_map_headers: undefined,
  allow_custom_fields: undefined,
  allow_excel_export: undefined,
  allow_help_text: undefined,
  allow_hooks: undefined,
  allow_i18n: undefined,
  allow_initial_data: undefined,
  allow_js_results: undefined,
  allow_js_schema: undefined,
  allow_style_overrides: undefined,
  allow_user_transforms: undefined,
  has_dromo_branding: undefined,
  row_limit: undefined,
  saved_schema_only: false,
  schema_limit: undefined,
  write_only_storage: false,
  auto_date_fix: true,
};

const DefaultDeveloperSettings = DeveloperSettingsSchema.parse({
  importIdentifier: "",
});

const initialState: ISettingsReduxState = {
  licenseKey: "",
  importIdentifier: "",
  title: null,
  user: { id: "" },
  styleOverrides: DefaultDeveloperSettings.styleOverrides,
  importerMode: "INIT",
  backend: {
    url: DEFAULT_API_URL,
    type: "AWS",
  },
  backendCapabilities: defaultBackendCapabilities,
  maxRecords: DefaultDeveloperSettings.maxRecords,
  maxFileSize: DefaultDeveloperSettings.maxFileSize,
  browserExcelParsing: DefaultDeveloperSettings.browserExcelParsing,
  headerRowOverride: DefaultDeveloperSettings.matchingStep.headerRowOverride,
  maxMappableSelectValues: DEFAULT_MAX_UNIQUE_VALUES_SELECT_FIELD,
  delimiter: DefaultDeveloperSettings.delimiter,
  autoMapHeaders: DefaultDeveloperSettings.autoMapHeaders,
  embedInline: false,
  savedSchema: null,
  invalidDataBehavior:
    DefaultDeveloperSettings.invalidDataBehavior as EInvalidDataBehavior,
  allowEmptySubmit: DefaultDeveloperSettings.allowEmptySubmit,
  appHost: null,
  backendSyncMode: DefaultDeveloperSettings.backendSyncMode as EBackendSyncMode,
  webhookUrl: DefaultDeveloperSettings.webhookUrl,
  manualInputDisabled: DefaultDeveloperSettings.manualInputDisabled ?? false,
  manualInputOnly: DefaultDeveloperSettings.manualInputOnly,
  templateDownloadFilename: DefaultDeveloperSettings.templateDownloadFilename,
  uploadStep: DefaultDeveloperSettings.uploadStep,
  matchingStep: DefaultDeveloperSettings.matchingStep,
  matchValuesStep: DefaultDeveloperSettings.matchValuesStep,
  reviewStep: DefaultDeveloperSettings.reviewStep,
  allowCustomFields: DefaultDeveloperSettings.allowCustomFields,
  passThroughUnmappedColumns:
    DefaultDeveloperSettings.passThroughUnmappedColumns,
  version: DefaultDeveloperSettings.version,
  isHeadless: false,
  savedSchemaHooks: { columnHooks: [], rowHooks: [], stepHooks: [] },
  operations: [],
};

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setLicenseKey: (state, action: PayloadAction<string>) => {
      state.licenseKey = action.payload;
    },

    setAppHost: (state, action: PayloadAction<string>) => {
      state.appHost = action.payload;
    },

    setUser: (state, action: PayloadAction<IUser>) => {
      state.user = action.payload;
    },

    setBackendCapabilities: (
      state,
      action: PayloadAction<IBackendCapabilities>
    ) => {
      state.backendCapabilities = action.payload;
    },

    setInitialImporterMode: (
      state,
      action: PayloadAction<boolean | undefined>
    ) => {
      state.importerMode =
        action.payload !== undefined
          ? action.payload
            ? "DEVELOPMENT"
            : "PRODUCTION"
          : "INIT";
    },

    setPreInitFromDeveloperSettings: (
      state,
      action: PayloadAction<IDeveloperSettingsStrict>
    ) => {
      state.backend = action.payload.backendOverride;
    },

    setFromDeveloperSettings: (
      state,
      action: PayloadAction<IDeveloperSettingsStrict>
    ) => {
      return {
        ...state,
        importIdentifier: action.payload.importIdentifier,
        title: action.payload.title ?? null,
        styleOverrides: action.payload.styleOverrides,
        backend: action.payload.backendOverride,
        browserExcelParsing: action.payload.browserExcelParsing,
        maxRecords: action.payload.maxRecords,
        maxFileSize: action.payload.maxFileSize,
        headerRowOverride: action.payload.matchingStep.headerRowOverride,
        maxMappableSelectValues:
          action.payload.matchValuesStep.maxMappableSelectValues,
        delimiter: action.payload.delimiter,
        autoMapHeaders: action.payload.autoMapHeaders,
        invalidDataBehavior: action.payload
          .invalidDataBehavior as EInvalidDataBehavior,
        allowEmptySubmit: action.payload.allowEmptySubmit,
        backendSyncMode: action.payload.backendSyncMode as EBackendSyncMode,
        webhookUrl: action.payload.webhookUrl,
        manualInputDisabled: action.payload.manualInputDisabled ?? false,
        manualInputOnly: action.payload.manualInputOnly,
        templateDownloadFilename: action.payload.templateDownloadFilename,
        uploadStep: action.payload.uploadStep,
        matchingStep: action.payload.matchingStep,
        matchValuesStep: action.payload.matchValuesStep,
        reviewStep: action.payload.reviewStep,
        allowCustomFields: action.payload.allowCustomFields,
        passThroughUnmappedColumns: action.payload.passThroughUnmappedColumns,
        version: action.payload.version,
      };
    },

    setSavedSchema: (state, action: PayloadAction<ISavedSchema>) => {
      state.savedSchema = action.payload;
    },

    setHeaderRowOverride: (state, action: PayloadAction<number | null>) => {
      state.headerRowOverride = action.payload;
    },

    setImporterMode: (state, action: PayloadAction<TImporterMode>) => {
      state.importerMode = action.payload;
    },

    setMaxRecords: (state, action: PayloadAction<number>) => {
      state.maxRecords = action.payload;
    },

    setOriginalSettings: (state, action: PayloadAction<IDeveloperSettings>) => {
      state.originalSettings = action.payload;
    },

    setIsHeadless: (state) => {
      state.isHeadless = true;
    },

    setEmbedInline: (state, action: PayloadAction<boolean>) => {
      state.embedInline = action.payload;
    },

    setSavedSchemaHooks: (state, action: PayloadAction<IAllHooks>) => {
      state.savedSchemaHooks = action.payload;
    },

    setOperations: (state, action: PayloadAction<IOperation[]>) => {
      state.operations = action.payload;
    },
  },
});

export const selectGlobalStyle = (state: RootState) => ({
  color: state.settings.styleOverrides.global.textColor,
});

export const selectAllowedExtensions = (state: RootState): string[] => {
  const { backendSyncMode, browserExcelParsing } = state.settings;
  const writeOnlyStorage =
    state.settings.backendCapabilities.write_only_storage;
  const extensions =
    backendSyncMode === EBackendSyncMode.FULL_DATA && !writeOnlyStorage
      ? allFiletypes
      : localFiletypes;

  return [
    ...new Set([
      ...selectCustomFileParserExtensions(state),
      ...extensions,
      ...(browserExcelParsing ? ["xlsx", "xls"] : []),
    ]),
  ]
    .map((ext) => `.${ext}`)
    .sort();
};

export const selectAIMatching = (state: RootState): boolean =>
  (state.settings.backendSyncMode === EBackendSyncMode.FULL_DATA ||
    state.settings.backendSyncMode === EBackendSyncMode.MAPPINGS_ONLY) &&
  state.settings.matchingStep.fuzzyMatchHeaders &&
  state.settings.backendCapabilities.ai_matching;

export type IDeveloperSettingsForExport = Omit<
  IDeveloperSettings,
  "initialData" | "initialFile"
> & {
  initialData: boolean;
  initialFile: boolean;
};

export const selectOriginalSettingsForExport = (
  state: RootState
): IDeveloperSettingsForExport | undefined => {
  const { originalSettings } = state.settings;
  if (originalSettings === undefined) {
    return originalSettings;
  }

  return {
    ...originalSettings,
    initialData: !!originalSettings.initialData,
    initialFile: !!originalSettings.initialFile,
  };
};

export const {
  setLicenseKey,
  setAppHost,
  setUser,
  setBackendCapabilities,
  setInitialImporterMode,
  setPreInitFromDeveloperSettings,
  setFromDeveloperSettings,
  setSavedSchema,
  setHeaderRowOverride,
  setImporterMode,
  setMaxRecords,
  setOriginalSettings,
  setIsHeadless,
  setEmbedInline,
  setSavedSchemaHooks,
  setOperations,
} = settingsSlice.actions;
export default settingsSlice.reducer;
