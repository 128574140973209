import { AbstractField, BaseTypeOpts, ITransformResult } from "../abstract";
import presets, { NumberPresetName } from "./presets";
import numbro from "numbro";
import { round } from "lodash";

export interface NumberOpts extends BaseTypeOpts {
  preset?: NumberPresetName;
  round?: number;
  displayFormat?: numbro.Format;
  outputFormat?: numbro.Format;
  min?: number;
  max?: number;
}

export type { NumberPresetName };

const currencySymbols = /[$€£¥₽]/g;

export class NumberField extends AbstractField<NumberOpts, number> {
  static presets = presets;
  static defaultPreset = presets.default;
  static defaultInvalidValueMessage = "Invalid number";

  type = "number" as const;

  round: number | null;
  displayFormat: numbro.Format;
  outputFormat: numbro.Format | null;
  min: number | null;
  max: number | null;

  constructor(opts: NumberOpts) {
    super(opts);

    if (
      // if no number opts are given, use the default preset.
      !("preset" in opts) &&
      !("round" in opts) &&
      !("displayFormat" in opts) &&
      !("outputFormat" in opts)
    ) {
      opts = { ...opts, preset: "default" };
    }

    const preset = (opts.preset && NumberField.presets[opts.preset]) || {};

    this.round = opts.round ?? preset.round ?? null;
    this.displayFormat = {
      ...(preset.displayFormat || {}),
      ...(opts.displayFormat || {}),
    };
    this.min = opts.min ?? preset.min ?? null;
    this.max = opts.max ?? preset.max ?? null;

    if (preset.outputFormat && opts.outputFormat) {
      this.outputFormat = {
        ...preset.outputFormat,
        ...opts.outputFormat,
      };
    } else {
      this.outputFormat = preset.outputFormat || opts.outputFormat || null;
    }

    if (this.min !== null || this.max !== null) {
      let message = "Invalid number";

      if (this.min !== null && this.max === null) {
        message += `. Expecting number that is at least ${this.min}`;
      } else if (this.max !== null && this.min === null) {
        message += `. Expecting number that is at most ${this.max}`;
      } else if (this.min !== null && this.max !== null) {
        message += `. Expecting number between ${this.min} and ${this.max}`;
      }

      this.invalidValueMessage = opts.invalidValueMessage ?? message;
    }
  }

  transform(value: string): ITransformResult<number> {
    // numbro will only remove the configured global currency symbol before attempting to parse.
    // so just remove all the common ones first
    const stripped =
      typeof value === "string" ? value.replaceAll(currencySymbols, "") : value;

    let parsedValue = numbro.unformat(stripped);
    const unformatted = parsedValue;

    if (parsedValue != null) {
      if (this.round !== null) parsedValue = round(parsedValue, this.round);

      if (this.min !== null && parsedValue < this.min) {
        return this.transformFailure();
      }

      if (this.max !== null && parsedValue > this.max) {
        return this.transformFailure();
      }

      return this.transformSuccess(parsedValue, unformatted !== parsedValue);
    }

    return this.transformFailure();
  }

  getDisplayValue(value: number): string {
    return numbro(value).format(this.displayFormat);
  }

  getOutputValue(value: number): string | number {
    if (this.outputFormat) {
      return numbro(value).format(this.outputFormat);
    } else {
      return value;
    }
  }

  // for editing, we don't want any special formatting
  getEditValue(value: number): string {
    if (this.displayFormat.output === "percent") {
      return numbro(value).format({
        output: "percent",
        mantissa: this.displayFormat.mantissa,
      });
    } else {
      return numbro(value).format();
    }
  }
}
