import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";

import { ReactComponent as RemoveIcon } from "../../assets/remove.svg";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import {
  matchCustomSelectFieldOption,
  ISelectFieldOption,
  MatchType,
  MatchTypes,
  updateSelectFieldMapping,
} from "../../store/reducers/fields";
import {
  exactMatchSelectOptions,
  aiOrFuzzyMatchSelectOptions,
} from "../../thunks/column_matching";
import Spinner from "../commonComponents/Spinner";
import { selectAIMatching } from "../../store/reducers/settings";
import { Select } from "../commonComponents/Select";
import Text from "../commonComponents/Text";
import { Button } from "../commonComponents/Button";
import {
  matchTypeToI18nKey,
  unmatchedI18nKey,
} from "../ColumnMatch/ColumnMatchCard";
import BulkFixCard from "./BulkFixCard";
import { IDeveloperSelectOption } from "../../interfaces";

interface ISelectMatchCardProps {
  name: string;
  options: {
    label: string;
    value: string;
    alternateMatches?: string[];
  }[];
  values: string[];
  columnIndex: number;
  filterMatched: boolean;
  exactMatchOnly: boolean;
  allowCustom: boolean;
}

export const SelectMatchCard: React.FC<ISelectMatchCardProps> = ({
  name,
  options,
  values,
  columnIndex,
  filterMatched,
  exactMatchOnly,
  allowCustom,
}) => {
  const { selectFieldMapping, aiSelectOptionStatus, aiMatchingEnabled } =
    useAppSelector((state) => ({
      selectFieldMapping: state.fields.selectFieldMapping,
      aiSelectOptionStatus: state.coredata.aiMatchSelectOptionStatus,
      aiMatchingEnabled: selectAIMatching(state),
    }));
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (exactMatchOnly) {
      dispatch(exactMatchSelectOptions(columnIndex));
    } else {
      dispatch(aiOrFuzzyMatchSelectOptions(columnIndex));
    }
  }, [columnIndex, dispatch, exactMatchOnly]);

  const handleChange = (
    uniqueValue: string,
    newValue: ISelectFieldOption | null
  ) => {
    dispatch(
      updateSelectFieldMapping(
        columnIndex,
        uniqueValue,
        newValue === null
          ? null
          : {
              ...newValue,
              matchType: "USER",
            }
      )
    );
  };

  const handleCreate = (uniqueValue: string, value: string) => {
    dispatch(
      matchCustomSelectFieldOption([
        columnIndex,
        uniqueValue,
        { label: value, value },
      ] as [number, string, IDeveloperSelectOption])
    );
  };

  const showSpinner =
    aiMatchingEnabled && aiSelectOptionStatus.get(columnIndex) !== "fulfilled";

  const colMapping = selectFieldMapping?.get(columnIndex);

  const unmatchedValues = values.filter((value) => {
    const matchType = colMapping?.get(value)?.matchType;

    if (!matchType) {
      return true;
    }

    return !(
      [MatchTypes.Exact, MatchTypes.AutoMap, MatchTypes.User] as MatchType[]
    ).includes(matchType);
  });

  const isAllMatched = unmatchedValues.length === 0;

  const displayValues = filterMatched ? unmatchedValues : values;

  return (
    <BulkFixCard
      columnName={name}
      instructionText={t("dataCleaningModal.selectField.instruction")}
    >
      {showSpinner ? (
        <div className="flex flex-col items-center mb-3">
          <Spinner role="status" />
        </div>
      ) : isAllMatched && filterMatched ? (
        <div className="px-4 py-4 bg-white border-t">
          <Text type="medium" className="text-ice-600 text-center">
            {t("dataCleaningModal.allValuesMatched")}
          </Text>
        </div>
      ) : values.length === 0 ? (
        <div className="px-4 py-4 bg-white border-t">
          <Text type="medium" className="text-ice-600 text-center">
            {t("dataCleaningModal.selectField.tooManyValues")}
          </Text>
        </div>
      ) : (
        <>
          <div className="grid grid-cols-2 divide-x divide-ice-300 border-ice-300 !border-t">
            <Text type="medium" className="px-4 py-2">
              {t("dataCleaningModal.selectField.yourValue")}
            </Text>
            <Text type="medium" className="px-4 py-2">
              {t("dataCleaningModal.selectField.matchesTo")}
            </Text>
          </div>
          <div className="border-t border-ice-300 divide-y divide-ice-300 bg-white">
            {displayValues.map((uniqueValue: string) => {
              const mappedOption = selectFieldMapping
                ?.get(columnIndex)
                ?.get(uniqueValue);

              const optionsWithMatch = options.map((option) =>
                option.value === mappedOption?.value
                  ? {
                      ...option,
                      matchType:
                        typeof mappedOption.matchType === "string"
                          ? matchTypeToI18nKey[mappedOption.matchType]
                          : null,
                    }
                  : option
              );

              const initialCustomValue =
                !allowCustom ||
                mappedOption?.value === uniqueValue ||
                options.find((option) => option.value === uniqueValue)
                  ? undefined
                  : uniqueValue;

              return (
                <div
                  key={uniqueValue}
                  className="grid grid-cols-2 divide-x divide-ice-300 items-center"
                  data-cy="clean-data-select-value-item"
                >
                  <Text type="medium" className="px-4 py-2">
                    {uniqueValue}
                  </Text>
                  <div className="px-4 py-3 flex gap-2">
                    <div className="w-full grow">
                      <Select
                        data-cy="selectOptionMatchingControl"
                        name={`select-${uniqueValue}`}
                        onChange={(changeArgs) => {
                          const newValue: ISelectFieldOption = {
                            alternateMatches: changeArgs.alternateMatches,
                            label: changeArgs.label,
                            value: changeArgs.value,
                          };
                          return handleChange(uniqueValue, newValue);
                        }}
                        value={{
                          ...mappedOption,
                          matchType:
                            typeof mappedOption?.matchType === "string"
                              ? matchTypeToI18nKey[mappedOption.matchType]
                              : unmatchedI18nKey,
                        }}
                        options={optionsWithMatch}
                        onCreate={
                          allowCustom
                            ? (value) => {
                                handleCreate(uniqueValue, value);
                              }
                            : undefined
                        }
                        initialCustomValue={initialCustomValue}
                      />
                    </div>

                    <Button
                      theme="ghost"
                      onClick={() => handleChange(uniqueValue, null)}
                      className={twMerge(
                        "gap-1 !p-[6px] !text-sm font-medium max-h-10 whitespace-nowrap",
                        !mappedOption && "opacity-0 pointer-events-none"
                      )}
                      data-cy="select-option-clear-button"
                    >
                      <RemoveIcon />
                      {t("common.clear")}
                    </Button>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      )}
    </BulkFixCard>
  );
};

export default React.memo(SelectMatchCard);
