import * as Sentry from "@sentry/browser";
import axios, { AxiosInstance } from "axios";
import {
  AI_MATCHING_MAX_TERMS,
  AI_MATCHING_TIMEOUT_MS,
  DEFAULT_API_URL,
} from "../constants/constants";
import {
  GetRawUploadResponse,
  GetRawUploadParams,
  GetDownloadUrlResponse,
  GetSuggestedMappingResponse,
  GetSavedSchemaResponse,
  OnCompletedUploadParams,
  OnCompletedUploadResponse,
  UpdateCompletedUploadMetadataParams,
  UpdateCompletedUploadMetadataResponse,
  UploadType,
  GetUploadResponse,
  UploadStatus,
  GetSignedUploadUrlResponse,
  VerifyLicenseKeyResponse,
  GetAIMatchesParams,
  GetAIMatchesResponse,
  UpdateHeadlessImportParams,
  BackendHeadlessImport,
  GetUserFunctionsParams,
  UserFunctionsResponse,
  UpdateUserFunctionsParams,
} from "../interfaces/api";
import { IColumnMappingCacheSerialized } from "./ColumnMappingCacheHelper";

export default class APIClient {
  readonly client: AxiosInstance;

  constructor(licenseKey: string, backendUrl = DEFAULT_API_URL) {
    this.client = axios.create({
      baseURL: backendUrl,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-Dromo-License-Key": licenseKey,
      },
    });
  }

  async verifyLicenseKey(appHost: string): Promise<VerifyLicenseKeyResponse> {
    const response = await this.client.get<VerifyLicenseKeyResponse>(
      "/api/widget/auth/verify/",
      {
        params: { app_host: appHost },
      }
    );

    return response.data;
  }

  async getRawUploadMetadata(
    rawUploadId: string
  ): Promise<GetRawUploadResponse> {
    const response = await this.client.get<GetRawUploadResponse>(
      `/api/widget/upload/${rawUploadId}/`,
      { params: { uploadType: "raw" } }
    );

    return response.data;
  }

  async getSignedUploadUrl(
    params: GetRawUploadParams
  ): Promise<GetSignedUploadUrlResponse> {
    const response = await this.client.post<GetSignedUploadUrlResponse>(
      "/api/widget/upload/",
      params
    );

    return response.data;
  }

  async getUpload<T extends UploadType = UploadType>(
    uploadType: T,
    uploadId: string
  ): Promise<GetUploadResponse<T>> {
    const response = await this.client.get(`/api/widget/upload/${uploadId}/`, {
      params: { uploadType },
    });

    return response.data;
  }

  async updateUpload<T extends UploadType = UploadType>(
    uploadType: T,
    uploadId: string,
    uploadStatus: UploadStatus
  ): Promise<GetUploadResponse<T>> {
    const response = await this.client.post(`/api/widget/upload/${uploadId}/`, {
      uploadType,
      uploadStatus,
    });

    return response.data;
  }

  async getRawDownloadUrl(rawUploadId: string): Promise<string> {
    const response = await this.client.get<GetDownloadUrlResponse>(
      `/api/widget/upload/${rawUploadId}/download/`
    );

    return response.data.presigned_url;
  }

  async getProcessedDownloadUrl(processedDataId: string): Promise<string> {
    const response = await this.client.get<GetDownloadUrlResponse>(
      `/api/widget/processed/${processedDataId}/download/`
    );

    return response.data.presigned_url;
  }

  async getCleanedDownloadUrl(cleanedUploadId: string): Promise<string> {
    const response = await this.client.get<GetDownloadUrlResponse>(
      `/api/widget/cleaned/${cleanedUploadId}/download/`
    );

    return response.data.presigned_url;
  }

  async getSuggestedMapping(
    headers: string[],
    importIdentifier: string
  ): Promise<IColumnMappingCacheSerialized | undefined> {
    const response = await this.client.post<GetSuggestedMappingResponse>(
      "/api/widget/mapping/",
      {
        headers,
        importIdentifier,
      }
    );

    return response.data?.mapping;
  }

  async getSavedSchema(name: string): Promise<GetSavedSchemaResponse | null> {
    try {
      const response = await this.client.get<GetSavedSchemaResponse>(
        `/api/widget/schema/${name}/`
      );
      return response.data;
    } catch (err) {
      if (
        axios.isAxiosError(err) &&
        err.response &&
        err.response.status === 404
      ) {
        return null;
      } else {
        throw err;
      }
    }
  }

  async onCompletedUpload(
    params: OnCompletedUploadParams
  ): Promise<OnCompletedUploadResponse> {
    const response = await this.client.post<OnCompletedUploadResponse>(
      "/api/widget/upload/completed/",
      params
    );

    return response.data;
  }

  async updateCompletedUploadMetadata(
    params: UpdateCompletedUploadMetadataParams
  ): Promise<UpdateCompletedUploadMetadataResponse> {
    const response =
      await this.client.post<UpdateCompletedUploadMetadataResponse>(
        "/api/widget/upload/completed/metadata/",
        params
      );

    return response.data;
  }

  async getAIMatches(
    params: GetAIMatchesParams
  ): Promise<GetAIMatchesResponse | null> {
    try {
      if (
        params.matches.length + params.queries.flatMap((q) => q).length >
        AI_MATCHING_MAX_TERMS
      ) {
        return null;
      }
      const response = await this.client.post<GetAIMatchesResponse>(
        "/api/widget/ai_matches/",
        params,
        { timeout: AI_MATCHING_TIMEOUT_MS }
      );
      return response.data;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        Sentry.withScope((scope) => {
          scope.setExtra("data", params);
          Sentry.captureException(err);
        });
        return null;
      } else {
        throw err;
      }
    }
  }

  async getUserFunction(
    params: GetUserFunctionsParams
  ): Promise<UserFunctionsResponse> {
    const response = await this.client.post<UserFunctionsResponse>(
      `/api/widget/user_functions/`,
      params
    );

    return response.data;
  }

  async updateUserFunction(
    id: string,
    params: UpdateUserFunctionsParams
  ): Promise<UserFunctionsResponse> {
    const response = await this.client.patch<UserFunctionsResponse>(
      `/api/widget/user_functions/${id}/`,
      params
    );

    return response.data;
  }

  async updateHeadlessImport(
    id: string,
    params: UpdateHeadlessImportParams
  ): Promise<BackendHeadlessImport> {
    const response = await this.client.patch<BackendHeadlessImport>(
      `/api/headless/${id}/`,
      params
    );

    return response.data;
  }
}
